import Card from '@shoreag/base/Card';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '@shoreag/base/Spinner';
import { Query } from 'react-apollo';
import get from 'lodash/get';
import KeyValuePairs from '../KeyValuePairs';
import Route from '../Route';
import datumQuery from '../../graphql/queries/datum.gql';
import enhanceKeyValuePairEntries from '../../utilities/enhance-key-value-pairs';
import filterKeyValuePairEntries from '../../utilities/filter-key-value-pair-entries';

const DatumDetailsPage = ({ id }) => (
  <Query query={datumQuery} variables={{ id }}>
    {({ data, loading }) =>
      loading ? (
        <Spinner />
      ) : (
        <Route
          header={{
            icon: 'info',
            title: get(data, 'datum.name', ''),
            type: 'datum',
          }}
          isPrivate
        >
          <Card pt={5}>
            <KeyValuePairs
              pairs={Object.entries(data.datum)
                .filter(filterKeyValuePairEntries)
                .map(enhanceKeyValuePairEntries)}
            />
          </Card>
        </Route>
      )
    }
  </Query>
);

DatumDetailsPage.propTypes = {
  id: PropTypes.string,
};

DatumDetailsPage.defaultProps = {
  id: null,
};

export default DatumDetailsPage;
