import isMobilePhone from 'validator/lib/isMobilePhone';

const inputReferenceKeyRegex = /^\$\$.+/;

const uuidRegexString =
  '[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}';

export const endWithLetter = (value) => {
  if (!/[a-z]$/i.test(value)) return 'Must end with a letter';
};

export const inputReferenceKey = (keys) => (value) => {
  if (inputReferenceKeyRegex.test(value) && !keys.includes(value)) {
    return 'Invalid input reference key';
  }
};

export const lettersNumbersDashesAndUnderscores = (value) => {
  if (!/^[a-z0-9-_]*$/i.test(value)) {
    return 'Must contain only letters, numbers, dashes and underscores';
  }
};

export const positive = (value) => {
  if (value && !/^[0-9]+$/i.test(value)) return 'Must be a positive number';
};

export const uniq = (values) => (value) => {
  if (values.includes(value)) return 'Must be unique';
};

export const uuid = (type) => (value) => {
  if (
    !inputReferenceKeyRegex.test(value) &&
    !new RegExp(`spr:bz:${type}::${uuidRegexString}`).test(value)
  ) {
    return `Invalid ${type} id`;
  }
};

export const startWithLetter = (value) => {
  if (!/^[a-z]/i.test(value)) return 'Must start with a letter';
};

export const phoneNumber = (value) => {
  if (value && !isMobilePhone(value, 'en-US')) {
    return 'Invalid phone number';
  }
};

export const sshRsa = (value) => {
  if (!/^ssh-rsa\s+[A-Za-z0-9+/]+[=]{0,3}(\s+.+)?\s*$/i.test(value)) {
    return 'Invalid public key';
  }
};

export const minimum = (limit) => (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(value) && (limit || limit === 0) && value < limit) {
    return `Should be greater than or equals to ${limit}`;
  }
};

export const maximum = (limit) => (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(value) && (limit || limit === 0) && value > limit) {
    return `Should be less than or equals to ${limit}`;
  }
};

export const isValidIpAddress = (value) => {
  if (
    value &&
    !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      value
    )
  ) {
    return 'Invalid dns/ip address';
  }
};
