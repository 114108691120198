import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactGA from 'react-ga';
import TextArea from '@shoreag/base/TextArea';
import arrayMutators from 'final-form-arrays';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { FORM_ERROR } from 'final-form';
import { Field, Form as FinalForm } from 'react-final-form';
import { Mutation } from 'react-apollo';
import { integer, required } from '@shoreag/validations';
import FormError from '../FormError';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import SubmitButton from '../SubmitButton';
import SubtleText from '../SubtleText';
import TagsForm from '../TagsForm';
import createDatumMutations from '../../graphql/mutations/create-datum.gql';
import isPermitted from '../../utilities/is-permitted';
import {
  COLUMN_DATA_TYPE_OPTIONS,
  DATE_FORMAT_OPTIONS,
  JSON_SCHEMA_FORMAT_OPTIONS,
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import { positive } from '../../utilities/validations';
import AllowedValuesForm from '../AllowedValuesForm';

const DatumCreatePage = ({ navigate }) => {
  const { user } = useContext(AuthContext);

  if (
    !isPermitted({
      desiredAction: PERMISSION_ACTIONS.W,
      desiredResource: PERMISSION_RESOURCES.DATUMS,
      ...user,
    })
  ) {
    return <NotFoundPage />;
  }

  return (
    <Route
      header={{
        icon: 'info',
        rightContainer: <SubtleText>* indicates required</SubtleText>,
        title: 'Create Datum',
        type: 'Datum',
      }}
      isPrivate
    >
      <Mutation mutation={createDatumMutations}>
        {(createDatum) => (
          <FinalForm
            mutators={{
              ...arrayMutators,
            }}
            onSubmit={async (values) => {
              let allowedValues = null;
              const isAllowedValues =
                values.allowedValues?.filter((i) => i).length > 0;
              if (isAllowedValues) {
                allowedValues = JSON.stringify(
                  values.allowedValues?.filter((i) => i)
                );
              }
              const updateValues = {
                ...values,
                allowedValues,
                format:
                  values.stringFormat === 'regex'
                    ? values.stringFormat
                    : values.format,
              };
              if (!isAllowedValues) {
                delete updateValues.allowedValues;
              }
              try {
                const {
                  data: {
                    createDatum: { id },
                  },
                } = await createDatum({ variables: { ...updateValues } });

                ReactGA.event({
                  action: 'Datum Created',
                  category: 'Datums',
                  label: id,
                });
                navigate(`/datums/${id}`);
              } catch (e) {
                return {
                  [FORM_ERROR]: get(e, 'graphQLErrors[0].message'),
                };
              }
            }}
            render={({
              form: {
                change,
                mutators: { push },
              },
              handleSubmit,
              submitError,
              submitting,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Card sx={{ maxWidth: 'maxWidths.form', mx: 'auto' }}>
                  <Field
                    component={Input}
                    label="Name *"
                    name="name"
                    validate={required}
                  />
                  <Field
                    component={TextArea}
                    label="Description *"
                    name="description"
                    validate={required}
                  />
                  <Field
                    component={Input}
                    label="Examples *"
                    name="examples"
                    validate={required}
                  />
                  <Field
                    component={Dropdown}
                    label="Definition Type *"
                    name="definitionType"
                    onChange={(value) => {
                      change('definitionType', value);

                      if (value === 'string') {
                        change('min', null);
                        change('max', null);
                        change('dateFormat', null);
                      } else if (value === 'float' || value === 'integer') {
                        change('pattern', null);
                        change('length', null);
                        change('format', null);
                        change('allowedValues', null);
                        change('stringFormat', null);
                        change('dateFormat', null);
                      } else if (value === 'boolean') {
                        change('min', null);
                        change('max', null);
                        change('dateFormat', null);
                        change('pattern', null);
                        change('length', null);
                        change('format', null);
                        change('allowedValues', null);
                        change('stringFormat', null);
                        change('dateFormat', null);
                      } else if (value === 'date') {
                        change('min', null);
                        change('max', null);
                        change('pattern', null);
                        change('length', null);
                        change('format', null);
                        change('allowedValues', null);
                        change('stringFormat', null);
                      }
                    }}
                    options={COLUMN_DATA_TYPE_OPTIONS}
                    validate={required}
                  />
                  <TagsForm push={push} />
                  {values.definitionType === 'date' && (
                    <Field
                      component={Dropdown}
                      isClearable
                      label="Select Date Format"
                      name="dateFormat"
                      options={DATE_FORMAT_OPTIONS}
                      validate={required}
                    />
                  )}
                  {values.definitionType === 'string' && (
                    <AllowedValuesForm push={push} />
                  )}
                  {values.definitionType === 'string' && (
                    <Field
                      component={Dropdown}
                      isClearable
                      label="String format"
                      name="stringFormat"
                      options={[
                        {
                          label: 'Built-In Formats',
                          value: 'built-in-formats',
                        },
                        { label: 'Regex', value: 'regex' },
                      ]}
                    />
                  )}
                  {values.definitionType === 'string' &&
                    values.stringFormat === 'built-in-formats' && (
                      <Field
                        component={Dropdown}
                        isClearable
                        label="Select Format"
                        name="format"
                        options={JSON_SCHEMA_FORMAT_OPTIONS}
                      />
                    )}
                  {values.definitionType === 'string' &&
                    values.stringFormat === 'regex' && (
                      <Field component={Input} label="Pattern" name="pattern" />
                    )}
                  {values.definitionType === 'string' && (
                    <Field
                      component={Input}
                      label="Maximum Length"
                      name="length"
                      validate={positive}
                    />
                  )}

                  {(values.definitionType === 'float' ||
                    values.definitionType === 'integer') && (
                    <Field
                      component={Input}
                      label="Minimum"
                      name="min"
                      validate={integer}
                    />
                  )}
                  {(values.definitionType === 'float' ||
                    values.definitionType === 'integer') && (
                    <Field
                      component={Input}
                      label="Maximum"
                      name="max"
                      validate={integer}
                    />
                  )}
                </Card>
                <FormError>{submitError}</FormError>
                <SubmitButton mt="lg" submitting={submitting}>
                  Save Datum
                </SubmitButton>
              </form>
            )}
          />
        )}
      </Mutation>
    </Route>
  );
};

DatumCreatePage.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default DatumCreatePage;
