import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import Icon from '../Icon';

const AllowedValuesFormSection = ({ name, index, fields }) => {
  const isFirst = index === 0;
  return (
    <Box key={name} alignItems="flex-start" display="flex" mt="sm">
      <Field
        component={Input}
        label={isFirst ? 'Allowed Values' : ''}
        name={name}
        placeholder="value"
        wrapperSx={{ mr: 4, mt: 0 }}
      />
      <Icon
        cursor="pointer"
        height="15px"
        mt={isFirst ? '32px' : '15px'}
        onClick={() => fields.remove(index)}
        svg="close"
        width="15px"
      />
    </Box>
  );
};

AllowedValuesFormSection.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
  }),
  index: PropTypes.number,
  name: PropTypes.string,
};

AllowedValuesFormSection.defaultProps = {
  fields: {},
  index: 0,
  name: '',
};

export default AllowedValuesFormSection;
