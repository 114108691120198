import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Table from '@shoreag/base/Table';
import copy from 'clipboard-copy';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import get from 'lodash/get';
import { ApolloConsumer, Query } from 'react-apollo';
import { AuthContext } from '@shoreag/auth';
import ButtonGroups from '../ButtonGroups';
import DashboardFilterForm from '../DashboardFilterForm';
import DashboardPaginator from '../DashboardPaginator';
import DotsIcon from '../DotsIcon';
import LinkWithVersion from '../LinkWithVersion';
import ResponsiveEllipsis from '../ResponsiveEllipsis';
import Route from '../Route';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import Tags from '../Tags';
import ToolbarButton from '../ToolbarButton';
import allDatumQuery from '../../graphql/queries/all-datum.gql';
import dashboardConstants from '../../utilities/dashboard-constants';
import isPermitted from '../../utilities/is-permitted';
import setActionDropdown from '../../utilities/set-action-dropdown';
import setDashboardURL from '../../utilities/set-dashboard-url';
import updateDatumStatusMutation from '../../graphql/mutations/update-datum-status.gql';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import AllowedValuesPillsGroup from '../AllowedValuesPillsGroup';

const DatumDashboardPage = ({ location, navigate }) => {
  const { user } = useContext(AuthContext);
  const [showFilters, setShowFilters] = useState(false);

  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'datum',
  });

  const query = JSON.stringify(
    dashboardConstants.getQuery({
      filters,
      page: pageCriteria,
      sort: sortCriteria,
    })
  );

  const hasWritePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.DATUMS,
    ...user,
  });

  return (
    <ApolloConsumer>
      {(client) => (
        <Route
          header={{
            icon: 'info',
            rightContainer: (
              <ButtonGroups>
                <ToolbarButton
                  active={showFilters || filters.filterApplied}
                  icon={filters.filterApplied ? 'filter' : 'menu'}
                  label="Filters"
                  onClick={() => setShowFilters(!showFilters)}
                />
                {hasWritePermission && (
                  <ToolbarButton
                    icon="plus"
                    label="Create Datum"
                    link="/datums/create"
                  />
                )}
              </ButtonGroups>
            ),
            title: 'Dashboard',
            type: 'datum',
          }}
          isPrivate
        >
          <DashboardFilterForm
            filters={filters}
            page={pageCriteria}
            showFilters={showFilters}
            sort={sortCriteria}
          />
          <Query
            fetchPolicy="network-only"
            notifyOnNetworkStatusChange
            query={allDatumQuery}
            variables={{ searchBody: query }}
          >
            {({ data, loading }) => {
              const datums = get(data, 'allDatum', []);

              return (
                <>
                  <Card m={0} p={5}>
                    <Table
                      header={[
                        { label: 'Name', value: 'name' },
                        'Description',
                        'Tags',
                        { label: 'Creation Date', value: 'createdAt' },
                        { label: 'Type', value: 'definitionType' },
                        'Min',
                        'Max',
                        'Allowed Values',
                        { label: 'Status', value: 'isDeleted' },
                        '',
                      ]}
                      isLoading={loading}
                      onSortUpdate={(sortBy, orderBy) => {
                        return navigate(
                          setDashboardURL({
                            filters,
                            filterValues: filters.values,
                            page: pageCriteria,
                            pathname: location.pathname,
                            sort: {
                              sortBy,
                              sortDirection: orderBy,
                            },
                          }),
                          { replace: true }
                        );
                      }}
                      orderBy={sortCriteria.sortDirection}
                      rows={datums.map((d) => {
                        const {
                          dropdownItems,
                          dropdownActions,
                        } = setActionDropdown([
                          {
                            action: `/datums/${d.id}`,
                            display: true,
                            label: ['view', 'View'],
                          },
                          {
                            action: () =>
                              client.mutate({
                                mutation: updateDatumStatusMutation,
                                update: (cache, { data: { updateDatum } }) => {
                                  try {
                                    const data = cache.readQuery({
                                      query: allDatumQuery,
                                      variables: {
                                        searchBody: query,
                                      },
                                    });

                                    const datumList = [...data.allDatum];

                                    datumList.allDatum = datumList.map(
                                      (datum) => {
                                        if (datum.id === updateDatum.id) {
                                          return {
                                            ...datum,
                                            isDeleted: !d.isDeleted,
                                          };
                                        }

                                        return datum;
                                      }
                                    );

                                    cache.writeQuery({
                                      data: datumList,
                                      query: allDatumQuery,
                                      variables: {
                                        searchBody: query,
                                      },
                                    });
                                  } catch (e) {
                                    // noop
                                  }
                                },
                                variables: {
                                  datumId: d.id,
                                  isDeleted: !d.isDeleted,
                                },
                              }),
                            display: hasWritePermission,
                            label: d.isDeleted
                              ? ['deactivate, Activate']
                              : ['activate', 'Deactivate'],
                          },
                          {
                            action: () => copy(d.id),
                            display: true,
                            label: ['copy', 'Copy ID'],
                          },
                        ]);

                        return [
                          <LinkWithVersion
                            link={`/datums/${d.id}`}
                            name={d.name}
                          />,
                          <ResponsiveEllipsis text={d.description} />,
                          <Tags tags={d.tags} />,
                          formatDateTimeConcise(d.createdAt),
                          d.definitionType || '',
                          d.min || '',
                          d.max || '',
                          d.allowedValues ? (
                            <AllowedValuesPillsGroup
                              data={d.allowedValues.map((i) => ({
                                label: i,
                              }))}
                              viewOnly
                            />
                          ) : (
                            ''
                          ),
                          d.isDeleted ? 'Inactive' : 'Active',
                          <Box sx={{ float: 'right' }}>
                            <DropdownMenu
                              button={<DotsIcon />}
                              itemActions={dropdownActions}
                              items={dropdownItems}
                            />
                          </Box>,
                        ];
                      })}
                      sortBy={sortCriteria.sortBy}
                    />
                  </Card>
                  <TagTooltipBox />
                  <DashboardPaginator
                    filters={{
                      page: pageCriteria.offset,
                    }}
                    goToNextPage={() =>
                      navigate(
                        setDashboardURL({
                          filters,
                          filterValues: filters.values,
                          page: {
                            offset: pageCriteria.offset + pageCriteria.pageSize,
                            pageSize: pageCriteria.pageSize,
                          },
                          pathname: location.pathname,
                          sort: sortCriteria,
                        }),
                        { replace: true }
                      )
                    }
                    goToPreviousPage={() =>
                      navigate(
                        setDashboardURL({
                          filters,
                          filterValues: filters.values,
                          page: {
                            offset: pageCriteria.offset - pageCriteria.pageSize,
                            pageSize: pageCriteria.pageSize,
                          },
                          pathname: location.pathname,
                          sort: sortCriteria,
                        }),
                        { replace: true }
                      )
                    }
                    isClientSidePagination
                    pageTotal={datums.length}
                  />
                </>
              );
            }}
          </Query>
        </Route>
      )}
    </ApolloConsumer>
  );
};

DatumDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default DatumDashboardPage;
