import React from 'react';
import { Router } from '@reach/router';
import DatumCreatePage from '../../components/DatumCreatePage';
import DatumDashboardPage from '../../components/DatumDashboardPage';
import DatumDetailsPage from '../../components/DatumDetailsPage';
import NotFoundPage from './404';

const DatumsRouter = (props) => (
  <Router>
    <DatumCreatePage {...props} path="/datums/create" />
    <DatumDashboardPage {...props} path="/datums/" />
    <DatumDetailsPage {...props} path="/datums/:id" />
    <NotFoundPage default />
  </Router>
);

export default DatumsRouter;
