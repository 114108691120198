import React from 'react';
import PropTypes from 'prop-types';
import InputListWrapper from '../InputListWrapper';
import AllowedValuesFormSection from './AllowedValuesFormSection';

const AllowedValuesForm = ({ push }) => (
  <InputListWrapper
    name="allowedValues"
    push={() => push('allowedValues', null)}
    title="Add allowed value"
  >
    <AllowedValuesFormSection />
  </InputListWrapper>
);

AllowedValuesForm.propTypes = {
  push: PropTypes.func.isRequired,
};

export default AllowedValuesForm;
